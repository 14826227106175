<template>
  <b-card>
    <b-card-title>{{ $t('titles.request_website') }}</b-card-title>
    <b-form @submit.stop.prevent="saveRequest">
      <b-form-group
          :description="$t('request_website.description', {start: activeRequests.length, limit: maxActiveWebsites})">
        <b-input-group prepend="https://">
          <b-form-input v-model="newWebsite"></b-form-input>
          <b-input-group-append>
            <b-button variant="primary" @click="saveRequest" :disabled="maxWebsitesReached"
                      :placeholder="$t('placeholder.domain_of_website')" v-model="newWebsite">
              <feather-icon icon="SaveIcon"/>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-form>
    <b-table :items="requestedWebsites" :empty-text="$t('messages.no_data')" :fields="tableFields" :stacked="['xs', 'sm'].indexOf(breakpoint) > -1">
      <template #cell(createdAt)="data">
        {{ moment(data.item.createdAt.date).format('DD.MM.YYYY') }}
      </template>
      <template #head(domain)>
        {{ $t('general.website') }}
      </template>
      <template #head(createdAt)>
        {{ $t('general.requested_at') }}
      </template>
      <template #head(status)>
        {{ $t('general.status') }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BTable,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BButton,
  BForm
} from "bootstrap-vue";
import _ from "lodash"
import {mapActions} from "vuex";

export default {
  components: {
    BCard, BCardTitle, BTable, BFormInput,
    BInputGroup, BFormGroup, BInputGroupAppend,
    BButton, BForm
  },
  name: "UserWebsiteRequests",
  data() {
    return {
      newWebsite: null,
      maxActiveWebsites: 5,
      tableFields: [
        'domain',
        'createdAt',
        'status'
      ]
    }
  },
  metaInfo(){
    return {
      title: this.$t('page_titles.website_requests')
    }
  },
  computed: {
    maxWebsitesReached() {
      return this.activeRequests.length >= this.maxActiveWebsites;
    },
    activeRequests() {
      return _.filter(this.requestedWebsites, website => {
        return website.status !== 'finished' && website.status !== 'rejected';
      })
    },
    requestedWebsites() {
      return this.$store.state.website_requests.items;
    },
    breakpoint() {
      return this.$store.getters["app/currentBreakPoint"]
    },
  },
  mounted() {
    this.fetchWebsiteRequests();
  },
  methods: {
    ...mapActions('website_requests', ['fetchWebsiteRequests', 'storeWebsiteRequest']),
    saveRequest() {
      if (_.isEmpty(this.newWebsite)) {
        this.$notify().error('Please enter website domain.');
        return;
      }
      if (this.maxWebsitesReached) {
        this.$notify().error('Please wait until we process your current active requests.');
        return;
      }
      this.storeWebsiteRequest({
        domain: this.newWebsite
      }).then(() => {
        this.$notify().success('Website was added')
      }).catch(error => {
        this.$notify().error(error.message)
      }).finally(() => {
        this.newWebsite = null;
      })
    }
  }
}
</script>