<template>
  <div>
    <b-card>
      <b-card-title class="d-flex justify-content-between">
        <span>{{ $t('titles.automations') }}</span>
        <b-button @click="createAutomationDialog = true" variant="primary" size="sm">
          {{ $t('buttons.create_new_automation') }}
        </b-button>
      </b-card-title>


      <b-table :items="automations" :empty-text="$t('messages.no_automations')" :fields="tableFields" :stacked="['xs', 'sm'].indexOf(breakpoint) > -1">
        <template #cell(event)="data">
          <p>{{ getEventClassLabel(data.item.event) }}</p>
          <p v-for="(value, key) in data.item.eventData" :key="value" class="small text-muted mb-0">
            {{ key }}: {{ value }}
          </p>
        </template>
        <template #cell(actions)="data">
          <p>{{ getActionClassLabel(data.item.actions[0].class) }}</p>
          <p v-for="(value, key) in data.item.actions[0].data" :key="key" class="small text-muted mb-0">
            {{ key }}: {{ value }}
          </p>
        </template>
        <template #cell(lastExecuted)="data">
          {{
            data.item.lastExecuted ? moment(data.item.lastExecuted).format('DD.MM.YYYY HH:mm') : $t('messages.not_executed_yet')
          }}
        </template>
        <template #cell(buttons)="data">
          <b-button variant="danger" @click="deleteAutomationItem(data.item.id)" size="sm">{{ $t('buttons.delete') }}</b-button>
        </template>
      </b-table>

    </b-card>
    <b-modal v-model="createAutomationDialog" :title="$t('titles.create_new_automation')"
             @close="newAutomation = {}"
             @ok="saveAutomation"
             :ok-title="$t('buttons.save')"
    >

      <v-select v-model="newAutomation.event" :options="events" :placeholder="$t('placeholder.choose_event')"></v-select>
      <dynamic-component v-for="(field, key) in selectedEvent.fields" :key="key" :element="field"
                         v-model="field.value">

      </dynamic-component>
      <v-select v-model="newAutomation.action" :options="actions" :placeholder="$t('placeholder.choose_action')"
                class="mt-2"></v-select>
      <dynamic-component v-for="(field, key) in selectedAction.fields" :key="key" :element="field"
                         v-model="field.value">

      </dynamic-component>
    </b-modal>
  </div>
</template>

<script>
import {BCard, BCardTitle, BTable, BModal, BButton} from "bootstrap-vue";
import vSelect from 'vue-select'
import _ from "lodash"
import {mapActions} from "vuex";
import DynamicComponent from "@/views/pricex/elements/DynamicComponent";

export default {
  components: {
    DynamicComponent,
    BCard, BCardTitle, BTable, BModal, BButton, vSelect
  },
  name: "UserAutomations",
  metaInfo(){
    return {
      title: this.$t('page_titles.automations')
    }
  },
  data() {
    return {
      createAutomationDialog: false,
      newAutomation: {},
      tableFields: [
        'event', 'actions', 'failedTimes', 'lastExecuted', 'buttons'
      ]
    }
  },
  mounted() {
    this.fetchAutomations();
    this.fetchActions();
    this.fetchEvents()
  },
  computed: {
    events() {
      return this.$store.state.automations.events
    },
    actions() {
      return this.$store.state.automations.actions
    },
    automations() {
      return this.$store.state.automations.items
    },
    selectedEvent() {
      if (!this.newAutomation.event) {
        return {fields: []};
      }
      return _.first(this.events, event => {
        return event.class === this.newAutomation.event;
      })
    },
    selectedAction() {
      if (!this.newAutomation.action) {
        return {fields: []};
      }
      return _.first(this.actions, action => {
        return action.class === this.newAutomation.action;
      })
    },
    breakpoint() {
      return this.$store.getters["app/currentBreakPoint"]
    },
  },
  methods: {
    ...mapActions('automations', ['fetchAutomations', 'fetchEvents', 'fetchActions', 'storeAutomation', 'deleteAutomation']),
    getEventClassLabel(eventClass) {
      return _.get(_.find(this.events, {class: eventClass}), 'label');
    },
    getActionClassLabel(actionClass) {
      return _.get(_.find(this.actions, {class: actionClass}), 'label');
    },
    saveAutomation(bModalEvent) {
      bModalEvent.preventDefault()
      let data = {
        eventClass: this.newAutomation.event,
        actionClass: this.newAutomation.action,
        event: this.selectedEvent,
        action: this.selectedAction
      };
      this.storeAutomation(data).then(() => {
        this.createAutomationDialog = false;
        this.newAutomation = {};
        this.$notify().success(this.$t('messages.automation_saved'))
      }).catch(error => {
        this.$notify().error(error.message)
      })
    },
    deleteAutomationItem(automationId) {
      this.$swal({
        title: this.$t('questions.are_you_sure'),
        text: this.$t('messages.deleting_automation'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('buttons.yes_delete_it'),
        customClass: {
          confirmButton: 'btn-danger'
        }
      }).then(result => {
        if (!result.value) {
          return
        }
        this.deleteAutomation(automationId)
            .then(() => {
              this.$notify().success(this.$t('messages.automation_deleted'))
            })
            .catch(error => {
              this.$notify().error(error.message)
            })
      })

    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>