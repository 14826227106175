<template>
  <div>
    <div class="results-products px-1 px-md-2 px-lg-1 mr-lg-2">
      <product-listing :product="product"
                       v-for="(product, index) in products"
                       :key="product.id"
                       :position="index"
                       :preview="!disablePreview"
                       @preview="previewProduct = product"></product-listing>
      <b-alert variant="none" class="d-flex justify-content-center align-items-center" :show="pageLoading">
        <b-spinner class="mr-1" small type="grow" variant="primary"></b-spinner>
        {{ $t('titles.loading_products') }}
      </b-alert>
    </div>

    <product-preview :product="previewProduct" @next="nextProduct"
                     :visible="previewProduct !== null"
                     @hidden="() => previewProduct = null"
                     @close="() => previewProduct = null"
                     v-if="previewProduct !== null"
                     @previous="previousProduct">
    </product-preview>
    <products-compare></products-compare>
  </div>
</template>

<script>
// import {BButton} from "bootstrap-vue";
import ProductListing from "@/views/pricex/elements/Products/ProductListing";
import ProductPreview from "@/views/pricex/elements/Products/ProductPreview";
import ProductsCompare from "@/views/pricex/elements/Products/ProductsCompare";
import _ from "lodash"
import {BAlert, BSpinner} from "bootstrap-vue";

export default {
  components: {
    // BButton,
    ProductListing,
    ProductPreview,
    ProductsCompare,
    BAlert, BSpinner
  },
  name: "ProductsGrid",
  data() {
    return {
      previewProduct: null
    }
  },
  props: {
    products: {
      type: Array,
      required: true
    },
    disablePreview: {
      type: Boolean,
      required: false,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    pageLoading: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    nextProduct() {
      let index = _.findIndex(this.products, ['id', this.previewProduct.id]);
      if (index === this.products.length - 1) {
        return;
      }
      this.previewProduct = this.products[index + 1];
    },
    previousProduct() {
      let index = _.findIndex(this.products, ['id', this.previewProduct.id]);
      if (index === 0) {
        return;
      }
      this.previewProduct = this.products[index - 1];
    }
  }
}
</script>

<style scoped lang="scss">

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/mixins";

.results-products {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 0.2fr));
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

@include media-breakpoint-down(sm) {
  .results-products {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.product-preview {
  transition: all 0.3s ease;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 101;

  &.preview-enter-active {
    right: -25%;
  }

  &.preview-leave-active {
    right: -25%
  }

  .closeButton {
    position: absolute !important;
    left: 0;
    top: 50%;
    height: 25px;

    &::before {
      content: " ";
      display: block;
      border-top: 2px solid #cacbcb;
      transform: rotate(90deg);
      width: 25px;
      margin-left: -8px;
      position: absolute;
      transition: all 0.1s ease;
    }

    &::after {
      content: " ";
      display: block;
      border-bottom: 2px solid #cacbcb;
      transform: rotate(90deg);
      width: 25px;
      position: absolute;
      transition: all 0.1s ease;
    }

    &:hover {
      &::before {
        transform: rotate(135deg);
        margin-left: 0;
      }

      &::after {
        transform: rotate(45deg);
      }
    }
  }
}
@include media-breakpoint-up(lg) {
  .product-preview {
    z-index: 1001;
  }
}
</style>
