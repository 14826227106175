<template>
  <div>
    <b-card>
      <b-card-title class="d-flex justify-content-between">
        <span>{{ $t('titles.api_access') }}</span>
        <b-button href="https://documenter.getpostman.com/view/21744/TW74jkG5" target="_blank"
                  variant="primary" size="sm">
          {{ $t('buttons.api_documentation') }} <feather-icon icon="ExternalLinkIcon"></feather-icon>
        </b-button>
      </b-card-title>

      <personal-access-tokens></personal-access-tokens>
    </b-card>
  </div>
</template>
<script>
import {BCard, BCardTitle, BButton} from "bootstrap-vue";
import PersonalAccessTokens from "@/views/pricex/elements/Passport/PersonalAccessTokens";

export default {
  components: {
    PersonalAccessTokens,
    BCard, BCardTitle, BButton
  },
  metaInfo(){
    return {
      title: this.$t('page_titles.api_access')
    }
  },
  name: "UserApiAccess",
}
</script>