<template>
  <div>
    <b-card>
      <b-card-title class="d-flex justify-content-between">
        <span>{{ $t('titles.favorites') }}</span>
      </b-card-title>
      <b-tabs pills>
        <b-tab v-for="(list, index) in lists" :title="list.name" :key="index" lazy>
          <template #title>
            <div class="tab-title-container">
              {{ list.name }}
              <b-badge variant="danger" size="sm" class="deleteListBadge" @click="deleteListItem(list.id)" v-if="lists.length > 1">x</b-badge>
            </div>
          </template>
          <products-grid v-if="list.products.length" :products="list.products" :cols="['lg-5']"></products-grid>
          <b-alert variant="info" v-else show class="p-2">
            {{ $t('messages.this_list_doesnt_have_products_yet_use_search_to_f') }}
          </b-alert>
        </b-tab>
        <template #tabs-end>
          <b-nav-item role="presentation" @click.prevent="listModal = true">
            <feather-icon icon="PlusIcon"></feather-icon>
            {{ $t('buttons.create_list') }}
          </b-nav-item>
        </template>
      </b-tabs>
    </b-card>
    <create-list-modal v-model="listModal"></create-list-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BTabs,
  BTab,
  BNavItem,
  BAlert,
  BBadge
} from "bootstrap-vue";
import ProductsGrid from "@/views/pricex/elements/Products/ProductsGrid";
import {mapActions} from "vuex";
import CreateListModal from "@/views/pricex/elements/CreateListModal";

export default {
  components: {
    CreateListModal,
    BCard,
    BCardTitle,
    BTabs,
    BTab,
    BNavItem,
    BAlert,
    BBadge,
    ProductsGrid
  },
  name: "UserFavorites",
  metaInfo(){
    return {
      title: this.$t('page_titles.favorites')
    }
  },
  data() {
    return {
      listModal: false,
      listName: null,
    }
  },
  computed: {
    lists() {
      return this.$store.state.user.data.lists;
    }
  },
  methods: {
    ...mapActions('user', ['removeList']),
    deleteListItem(list_id) {
      this.$swal({
        title: this.$t('questions.are_you_sure'),
        text: this.$t('messages.deleting_the_list_is_permanent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('buttons.yes_delete_it'),
        customClass: {
          confirmButton: 'btn-danger'
        }
      }).then(result => {
        if (!result.value) {
          return
        }
        this.removeList(list_id).then(() => {
          this.$notify().success(this.$t('messages.list_removed'))
        }).catch(() => {
          this.$notify().error(this.$t('errors.error_removing_list'))
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.tab-title-container {
  position: relative;

  .deleteListBadge {
    position: absolute;
    top: -20px;
    right: -30px;
  }
}
</style>