<template>
  <div>
    <b-card no-body class="mt-2">
      <b-card-title class="d-flex justify-content-between align-items-center">
        <span>{{ $t('titles.personal_access_tokens') }}</span>
        <b-button tabindex="-1" @click="showCreateTokenForm" variant="success">
          {{ $t('buttons.create_new_token') }}
        </b-button>
      </b-card-title>
      <!-- No Tokens Notice -->
      <p class="mb-0" v-if="tokens.length === 0">
        {{ $t('messages.you_have_not_created_any_personal_access_tokens') }}
      </p>

      <!-- Personal Access Tokens -->
      <table class="table table-borderless mb-0" v-if="tokens.length > 0">
        <thead>
        <tr>
          <th>{{ $t('table.token_name') }}</th>
          <th></th>
        </tr>
        </thead>

        <tbody>
        <tr v-for="(token, index) in tokens" :key="index">
          <!-- Client Name -->
          <td style="vertical-align: middle;">
            {{ token.name }}
          </td>

          <!-- Delete Button -->
          <td style="vertical-align: middle;">
            <a class="action-link text-danger" @click="revoke(token)">
              {{ $t('buttons.delete') }}
            </a>
          </td>
        </tr>
        </tbody>
      </table>
    </b-card>

    <b-modal v-model="createTokenModal" :title="$t('titles.create_personal_access_token')"
             :cancel-title="$t('buttons.close')"
             :ok-title="$t('buttons.create')"
             @ok="store"
    >

      <div class="alert alert-danger" v-if="form.errors.length > 0">
        <p class="mb-0"><strong>{{ $t('errors.whoops') }}</strong> {{ $t('errors.something_went_wrong') }}</p>
        <br>
        <ul>
          <li v-for="(error, index) in form.errors" :key="index">
            {{ error }}
          </li>
        </ul>
      </div>

      <!-- Create Token Form -->

      <validation-observer ref="newTokenForm">
        <b-form @submit.stop.prevent="store">
          <b-form-group>
            <validation-provider
                #default="{ errors }"
                :name="$t('label.token')"
                rules="required"
            >
              <b-form-input v-model="form.name" :placeholder="$t('titles.personal_access_token_name')"
                            :state="errors.length > 0 ? false : null"></b-form-input>
              <b-form-invalid-feedback>{{ $t('errors.please_provide_a_name_for_the_token') }}</b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <label v-if="scopes.length > 0">{{ $t('titles.token_access') }}</label>
          <!-- Scopes -->
          <div v-if="scopes.length > 0">
            <div v-for="(scope, index) in scopes" :key="index">
              <div class="checkbox">
                <label>
                  <input type="checkbox"
                         @click="toggleScope(scope.id)"
                         :checked="scopeIsAssigned(scope.id)">

                  {{ scope.description }}
                </label>
              </div>
            </div>
          </div>
        </b-form>
      </validation-observer>

    </b-modal>

    <b-modal v-model="tokenAccessModal" :title="$t('titles.personal_access_token')"
             :cancel-title="$t('buttons.close')"
    >
      <p>
        {{ $t('messages.here_is_your_new_personal_access_token') }}
      </p>

      <textarea class="form-control" rows="10" v-model="accessToken"></textarea>
    </b-modal>

  </div>
</template>

<script>
import {BButton, BModal, BFormInput, BCard, BCardTitle, BForm, BFormGroup, BFormInvalidFeedback} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required} from '@validations'
import _ from "lodash"
import {mapActions} from "vuex";

export default {
  components: {
    BButton, BModal, BFormInput, BCard, BCardTitle, BForm, BFormGroup, BFormInvalidFeedback,
    ValidationProvider, ValidationObserver
  },
  name: 'PersonalAccessTokens',
  /*
   * The component's data.
   */
  data() {
    return {
      accessToken: null,
      createTokenModal: false,
      tokenAccessModal: false,

      form: {
        name: '',
        scopes: [],
        errors: []
      },
      required
    };
  },

  /**
   * Prepare the component (Vue 1.x).
   */
  ready() {
    this.prepareComponent();
  },

  /**
   * Prepare the component (Vue 2.x).
   */
  mounted() {
    this.prepareComponent();
  },

  computed: {
    tokens() {
      return this.$store.state.passport.personalAccessTokens
    },
    scopes() {
      return this.$store.state.passport.scopes
    }
  },

  methods: {
    ...mapActions('passport', ['fetchPersonalAccessTokens', 'fetchScopes', 'revokeToken', 'storeToken']),
    /**
     * Prepare the component.
     */
    prepareComponent() {
      this.fetchPersonalAccessTokens();
      this.fetchScopes();
    },

    /**
     * Show the form for creating new tokens.
     */
    showCreateTokenForm() {
      this.createTokenModal = true;
    },

    /**
     * Create a new personal access token.
     */
    store(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.newTokenForm.validate().then(success => {
        if (!success) {
          return
        }

        this.accessToken = null;
        this.storeToken(this.form)
            .then(response => {
              this.form.name = '';
              this.form.scopes = [];
              this.form.errors = [];

              this.showAccessToken(response.data.accessToken);
            })
            .catch(() => {
              //TODO notify
            });
      })
    },

    /**
     * Toggle the given scope in the list of assigned scopes.
     */
    toggleScope(scope) {
      if (this.scopeIsAssigned(scope)) {
        this.form.scopes = _.reject(this.form.scopes, s => s === scope);
      } else {
        this.form.scopes.push(scope);
      }
    },

    /**
     * Determine if the given scope has been assigned to the token.
     */
    scopeIsAssigned(scope) {
      return _.indexOf(this.form.scopes, scope) >= 0;
    },

    /**
     * Show the given access token to the user.
     */
    showAccessToken(accessToken) {
      this.createTokenModal = false;

      this.accessToken = accessToken;

      this.tokenAccessModal = true;
    },

    /**
     * Revoke the given token.
     */
    revoke(token) {
      this.$swal({
        title: this.$t('questions.are_you_sure'),
        text: this.$t('messages.cant_revert_this'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('buttons.yes_delete_it'),
        customClass: {
          confirmButton: 'btn-danger'
        }
      }).then(result => {
        if (!result.value) {
          return
        }
        this.revokeToken(token.id)
      })
    }
  }
}
</script>
