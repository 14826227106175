<template>
  <b-container class="mt-5">
    <b-tabs pills :vertical="['lg','xl'].indexOf(breakpoint) > -1" v-model="tabIndex">
      <b-tab :title="$t('buttons.dashboard')" @click="changeTabUrl('dashboard')" lazy>
        <user-dashboard></user-dashboard>
      </b-tab>
      <b-tab :title="$t('buttons.profile')" @click="changeTabUrl('profile')" lazy>
        <user-profile></user-profile>
      </b-tab>
      <b-tab :title="$t('titles.favorites')" @click="changeTabUrl('favorites')" lazy>
        <user-favorites></user-favorites>
      </b-tab>
      <b-tab :title="$t('titles.automations')" @click="changeTabUrl('automations')" lazy>
        <user-automations></user-automations>
      </b-tab>
      <b-tab :title="$t('buttons.website_requests')" @click="changeTabUrl('request')" lazy>
        <user-website-requests></user-website-requests>
      </b-tab>
      <b-tab :title="$t('titles.api_access')" @click="changeTabUrl('api-access')" lazy>
        <user-api-access></user-api-access>
      </b-tab>
    </b-tabs>
  </b-container>
</template>

<script>
import UserDashboard from "@/views/pricex/user/UserDashboard";
import {BTabs, BTab, BContainer} from "bootstrap-vue";
import UserProfile from "@/views/pricex/user/UserProfile";
import _ from 'lodash'
import UserFavorites from "@/views/pricex/user/UserFavorites";
import UserAutomations from "@/views/pricex/user/UserAutomations";
import UserWebsiteRequests from "@/views/pricex/user/UserWebsiteRequests";
import UserApiAccess from "@/views/pricex/user/UserApiAccess";

export default {
  name: "UserAccount",
  metaInfo(){
    return {
      title: this.$t('page_titles.profile')
    }
  },
  components: {
    UserApiAccess,
    UserWebsiteRequests,
    UserAutomations,
    UserFavorites,
    UserProfile,
    BTab, BTabs, BContainer,
    UserDashboard
  },
  data() {
    return {
      tabIndex: 0,
      sectionsIndexes: {
        'dashboard': 0,
        'profile': 1,
        'favorites': 2,
        'automations': 3,
        'request': 4,
        'api-access': 5
      }
    }
  },
  computed: {
    breakpoint() {
      return this.$store.getters["app/currentBreakPoint"]
    },
  },
  created() {
    this.tabIndex = _.get(this.sectionsIndexes, this.$route.params.section)
  },
  methods: {
    changeTabUrl(section) {
      if (this.$route.params.section === section) {
        return
      }
      this.$router.push({name: 'user.account', params: {section: section}})
    }
  },
  watch: {
    $route(to){
      if(to.name === 'user.account'){
        this.tabIndex = _.get(this.sectionsIndexes, to.params.section)
      }
    }
  }
}
</script>