<template>
  <div>
    <v-select
        v-bind="$attrs"
        v-on="$listeners"
        v-if="element.tag === 'p-autocomplete'"
        label="label"
        :reduce="v => v.value"
        :options="element.options || remoteOptions"
        :filtarable="element.props.endpoint !== undefined"
        :placeholder="element.props.label"
        @search="fetchOptions"
        class="mt-1"
    >

    </v-select>
    <b-form-input
        v-bind="$attrs"
        v-on="$listeners"
        v-if="element.tag === 'p-text-input'"
        :placeholder="element.props.label"
        class="mt-1"
    ></b-form-input>
  </div>
</template>

<script>
import {BFormInput} from "bootstrap-vue";
import vSelect from "vue-select"

export default {
  name: "DynamicComponent",
  components: {
    BFormInput, vSelect
  },
  props: {
    element: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      remoteOptions: []
    }
  },
  created() {
    if (this.element.props.endpoint) {
      this.fetchOptions('')
    }
  },
  methods: {
    fetchOptions(search) {
      this.$http.get(this.element.props.endpoint, {
        params: {
          q: search
        }
      }).then(response => {
        this.remoteOptions = response.data
      })
    }
  }
}
</script>