var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('b-card-title',{staticClass:"d-md-flex justify-content-between"},[_c('span',[_vm._v(_vm._s(_vm.$t('titles.profile_data')))]),_c('b-button',{attrs:{"variant":"primary","size":"sm"},on:{"click":_vm.downloadData}},[_vm._v(_vm._s(_vm.$t('buttons.download_profile_data')))])],1),_c('b-tabs',{attrs:{"pills":""}},[_c('b-tab',{attrs:{"title":_vm.$t('titles.general_information'),"active":""}},[_c('validation-observer',{ref:"profileForm"},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.storeUserInfo.apply(null, arguments)}}},[_c('b-form-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('titles.general.name')}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('label.email'),"description":_vm.$t('messages.email_change_info')}},[_c('validation-provider',{attrs:{"name":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.storeUserInfo}},[_vm._v(_vm._s(_vm.$t('buttons.save_changes')))])],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('titles.password_change')}},[_c('validation-observer',{ref:"passwordForm"},[_c('b-form',{on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.changePassword.apply(null, arguments)}}},[_c('b-form-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('labels.old_password')}},[_c('validation-provider',{attrs:{"name":"current_password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.password.old),callback:function ($$v) {_vm.$set(_vm.password, "old", $$v)},expression:"password.old"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('labels.new_password')}},[_c('validation-provider',{attrs:{"name":"password","rules":"required|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.password.new),callback:function ($$v) {_vm.$set(_vm.password, "new", $$v)},expression:"password.new"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"label":_vm.$t('labels.repeat_new_password')}},[_c('validation-provider',{attrs:{"name":"password_confirmation","rules":"required|confirmed:password|min:8"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.password.confirmation),callback:function ($$v) {_vm.$set(_vm.password, "confirmation", $$v)},expression:"password.confirmation"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.changePassword}},[_vm._v(_vm._s(_vm.$t('buttons.save_changes')))])],1)],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":_vm.$t('titles.profile_removal')}},[(!_vm.accountRemovalRequested)?_c('div',[_c('b-alert',{staticClass:"p-2",attrs:{"variant":"danger","show":""}},[_vm._v(" "+_vm._s(_vm.$t('questions.are_you_sure_that_you_want_to_remove_your_account'))+" ")]),_c('b-button',{attrs:{"variant":"danger"},on:{"click":_vm.askToDeleteProfile}},[_vm._v(_vm._s(_vm.$t('buttons.yes_delete_my_account')))])],1):_c('div',[_c('b-alert',{staticClass:"p-2",attrs:{"variant":"info","show":""}},[_vm._v(_vm._s(_vm.$t('messages.we_have_sent_confirmation_to_your_email_address'))+" ")])],1)]),_c('b-tab',{attrs:{"title":_vm.$t('titles.notifications')}},[_c('b-button',{staticClass:"progressier-subscribe-button",attrs:{"variant":"primary","data-icons":"false","data-eligible":_vm.$t('buttons.enable_system_notifications'),"data-subscribed":_vm.$t('buttons.subscribed'),"data-blocked":_vm.$t('buttons.notifications_blocked')}})],1),_c('b-tab',{attrs:{"title":_vm.$t('tabs.application')}},[_c('b-button',{staticClass:"progressier-install-button",attrs:{"variant":"primary","data-behavior":"disable","data-icons":"false","data-install":_vm.$t('buttons.install_application'),"data-installed":_vm.$t('buttons.already_installed')}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }