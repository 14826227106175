<template>
  <b-card>
    <b-card-title class="d-md-flex justify-content-between">
      <span>{{ $t('titles.profile_data') }}</span>
      <b-button variant="primary" size="sm" @click="downloadData">{{ $t('buttons.download_profile_data') }}</b-button>
    </b-card-title>

    <b-tabs pills>
      <b-tab :title="$t('titles.general_information')" active>
        <validation-observer ref="profileForm">
          <b-form @submit.stop.prevent="storeUserInfo">
            <b-form-row>
              <b-col lg="3">
                <b-form-group :label="$t('titles.general.name')"
                              class="required"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="name"
                      rules="required"
                  >
                    <b-form-input
                        v-model="user.name"
                        :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('label.email')"
                              class="required"
                              :description="$t('messages.email_change_info')"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="email"
                      rules="required|email"
                  >
                    <b-form-input type="email"
                                  v-model="user.email"
                                  :state="errors.length > 0 ? false : null"
                    ></b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <b-button variant="primary" @click="storeUserInfo">{{ $t('buttons.save_changes') }}</b-button>
              </b-col>
            </b-form-row>
          </b-form>
        </validation-observer>
      </b-tab>
      <b-tab :title="$t('titles.password_change')">
        <validation-observer ref="passwordForm">
          <b-form @submit.stop.prevent="changePassword">
            <b-form-row>
              <b-col lg="3">
                <b-form-group :label="$t('labels.old_password')" class="required">
                  <validation-provider
                      #default="{ errors }"
                      name="current_password"
                      rules="required"
                  >
                    <b-form-input type="password"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="password.old"></b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('labels.new_password')" class="required">
                  <validation-provider
                      #default="{ errors }"
                      name="password"
                      rules="required|min:8"
                  >
                    <b-form-input type="password"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="password.new"></b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <b-form-group :label="$t('labels.repeat_new_password')" class="required">
                  <validation-provider
                      #default="{ errors }"
                      name="password_confirmation"
                      rules="required|confirmed:password|min:8"
                  >
                    <b-form-input type="password"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="password.confirmation"></b-form-input>
                    <b-form-invalid-feedback>{{ errors[0] }}</b-form-invalid-feedback>
                  </validation-provider>
                </b-form-group>
                <b-form-group>
                  <b-button variant="primary" @click="changePassword">{{ $t('buttons.save_changes') }}</b-button>
                </b-form-group>
              </b-col>
            </b-form-row>
          </b-form>
        </validation-observer>
      </b-tab>
      <b-tab :title="$t('titles.profile_removal')">
        <div v-if="!accountRemovalRequested">
          <b-alert variant="danger" show class="p-2">
            {{ $t('questions.are_you_sure_that_you_want_to_remove_your_account') }}
          </b-alert>
          <b-button variant="danger" @click="askToDeleteProfile">{{ $t('buttons.yes_delete_my_account') }}</b-button>
        </div>
        <div v-else>
          <b-alert variant="info" show class="p-2">{{ $t('messages.we_have_sent_confirmation_to_your_email_address') }}
          </b-alert>
        </div>
      </b-tab>
      <b-tab :title="$t('titles.notifications')">
        <b-button variant="primary" class="progressier-subscribe-button" data-icons="false" :data-eligible="$t('buttons.enable_system_notifications')" :data-subscribed="$t('buttons.subscribed')" :data-blocked="$t('buttons.notifications_blocked')" ></b-button>
      </b-tab>
      <b-tab :title="$t('tabs.application')">
        <b-button variant="primary" class="progressier-install-button" data-behavior="disable" data-icons="false" :data-install="$t('buttons.install_application')" :data-installed="$t('buttons.already_installed')" ></b-button>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BForm,
  BFormInput,
  BFormGroup,
  BCol,
  BTabs,
  BTab,
  BButton,
  BFormRow,
  BAlert,
  BFormInvalidFeedback
} from "bootstrap-vue";
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {required, email, confirmed, min} from '@validations'
import {mapActions} from "vuex";

export default {
  components: {
    BCard,
    BCardTitle,
    BForm,
    BFormInput,
    BFormGroup,
    BCol,
    BTabs,
    BTab,
    BButton,
    BFormRow,
    BAlert,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver
  },
  name: "UserProfile",
  metaInfo(){
    return {
      title: this.$t('page_titles.profile')
    }
  },
  data() {
    return {
      required,
      email,
      confirmed,
      min,
      user: {
        name: '',
        email: ''
      },
      password: {
        old: '',
        new: '',
        confirmation: ''
      },
      accountRemovalRequested: false,
      deferredPrompt: null,
    }
  },
  computed: {
    oneSignalNotificationPrompt() {
      return JSON.parse(window.localStorage.getItem('onesignal-notification-prompt'));
    },
    isPushNotificationsEnabled() {
      return window.localStorage.getItem('isPushNotificationsEnabled') === 'true';
    },
    pwaInstalled() {
      return window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true || this.deferredPrompt === null;
    }
  },
  created() {
    let user = this.$store.state.user.data;
    this.user.name = user.name;
    this.user.email = user.email;
  },
  methods: {
    ...mapActions('user', ['update', 'updatePassword', 'deleteProfile']),
    storeUserInfo(formSubmitEvent) {
      formSubmitEvent.preventDefault()
      this.$refs.profileForm.validate().then(success => {
        if (!success) {
          return
        }
        this.update({
          name: this.user.name,
          email: this.user.email
        }).then(() => {
          this.$notify().success(this.$t('Profile updated'))
        }).catch(error => {
          this.$refs.profileForm.setErrors(error.response.data.errors)
        })
      })
    },
    changePassword(formSubmitEvent) {
      formSubmitEvent.preventDefault()
      this.$refs.passwordForm.validate().then(success => {
        if (!success) {
          return
        }
        this.updatePassword({
          current_password: this.password.old,
          password: this.password.new,
          password_confirmation: this.password.confirmation
        }).then(() => {
          this.$notify().success(this.$t('messages.password_updated'))
        }).catch(error => {
          this.$refs.passwordForm.setErrors(error.response.data.errors)
        })
      })
    },
    askToDeleteProfile() {
      this.$swal({
        title: this.$t('questions.are_you_sure'),
        text: this.$t('messages.delete_account_is_permanent'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('buttons.yes_delete_my_account'),
        customClass: {
          confirmButton: 'btn-danger'
        }
      }).then(result => {
        if (!result.value) {
          return
        }
        this.deleteProfile().then(() => {
          this.$notify().success(this.$t('messages.we_have_sent_confirmation_to_your_email_address'))
          this.accountRemovalRequested = true;
        }).catch(() => {
          this.$notify().error(this.$t('errors.unexpected_error'))
        })
      })
    },
    downloadData() {
      this.$http.get('/user/export')
          .then(response => {
            const url = window.URL.createObjectURL(new Blob([JSON.stringify(response.data)], {type: 'application/json'}));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = 'my-data.json';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
    }
  }
}
</script>