<template>
  <b-card>

    <!--                <div class="alert alert-info border-0 px-5 py-4 rounded-0 overflow-hidden">-->
    <!--                    <h3 class="text-info">{{t('Welcome %s', user.name)}}</h3>-->
    <!--                    <p>-->
    <!--                        <span class="fw-bold">{{t('Do you want to become seller?')}}</span><br/>-->
    <!--                        {{t('See our tips for successful business with PriceX')}}-->
    <!--                    </p>-->
    <!--                    <p-button class="btn-link fw-bold ps-0">{{t('View tips')}}</p-button>-->
    <!--                    <p-button class="btn-link">{{t('Not now')}}</p-button>-->
    <!--                    <p-img src="/images/office1.svg" class="info-banner"></p-img>-->
    <!--                </div>-->
    <b-card-title>{{ $t('titles.last_updated_favorites') }}</b-card-title>
    <b-alert variant="info" :show="!products.length" class="px-5 py-4">
      <p>{{ $t('messages.no_favorite_products_use_the_search_functionality') }}</p>
    </b-alert>
    <products-grid :products="products.splice(0, 5)" :cols="['lg-5']"></products-grid>
  </b-card>
</template>

<script>
import {BCard, BCardTitle, BAlert} from "bootstrap-vue";
import ProductsGrid from "@/views/pricex/elements/Products/ProductsGrid";
import _ from "lodash"

export default {
  components: {
    BCard, BCardTitle, BAlert,
    ProductsGrid
  },
  name: "UserDashboard",
  metaInfo(){
    return {
      title: this.$t('page_titles.dashboard')
    }
  },
  data() {
    return {
      previewProduct: null
    }
  },
  computed: {
    user() {
      return this.$store.state.user.data;
    },
    products() {
      let products = [];
      _.each(this.$store.state.user.data.lists, list => {
        _.each(list.products, product => {
          products.push(product);
        })
      })

      return products;
    }
  }
}
</script>